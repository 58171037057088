<template>
  <div class="comm-recruit-container center column">

    <div class="comm-notice">
      <div clsas="comm-recruit-title">
        <p class="comm-recruit-title-text">구인구직</p>
      </div>

    <hr>
    <table class="comm-recruit-table">
      <tr  class="comm-recruit-table-header">
        <th class="comm-recruit-table-number">NO</th>
        <th class="comm-recruit-table-title">제목</th>
        <th class="comm-recruit-table-company">취업처</th>
        <th class="comm-recruit-table-date">모집기간</th>
      </tr>

      <tr class="comm-recruit-table-content cursor"
      v-for="(item, index) in RECRUIT_LIST"
      :key="'comm-recruit-table'+index"
      @click="onPost(item._id)">
        <td class="comm-recruit-table-number">{{(RECRUIT_LIST_TOTAL) - index - ((pageIndex-1)*10)}}</td>
        <td class="comm-recruit-table-title">{{item.r_title}}</td>
        <td class="comm-recruit-table-company">{{item.c_title}}</td>
        <td class="comm-recruit-table-date">{{changeDateFormat(item.r_start)}} ~ {{changeDateFormat(item.r_end)}}</td>
      </tr>
    </table>

    <div class="comm-recruit-pagination-container">
      <button class="comm-recruit-pagination-btn cursor"
      @click="onPrevious()">
        <img class="comm-recruit-pagination-image" :src="paginationBtnLeftIcon"/>
      </button>
      <div class="comm-recruit-pagination">
        <span class="comm-recruit-pagination-current">{{pageIndex}}</span>
        /
        <span class="comm-recruit-pagination-total">{{totalIndex}}</span>
      </div>
      <button class="comm-recruit-pagination-btn cursor"
      @click="onNext()">
        <img class="comm-recruit-pagination-image" :src="paginationBtnRightIcon"/>
      </button>
    </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import paginationBtnLeftIcon from '../../assets/icons/pagination_btn_left_icon.svg';
import paginationBtnRightIcon from '../../assets/icons/pagination_btn_right_icon.svg';

import moment from 'moment';

export default {
  data() {
    return {
      pageIndex : 1,
      paginationBtnLeftIcon,
      paginationBtnRightIcon,
    }
  },

  created() {
    this.FETCH_RECRUIT_LIST(this.pageIndex)
  },

  methods : {
    ...mapActions([
      'FETCH_RECRUIT_LIST'
    ]),

    onPrevious() {
      if(this.pageIndex == 1) return
      this.pageIndex -= 1;
      this.FETCH_RECRUIT_LIST(this.pageIndex)
    },

    onNext() {
      if(this.pageIndex == this.totalIndex) return
      this.pageIndex += 1;
      console.log(this.pageIndex)
      this.FETCH_RECRUIT_LIST(this.pageIndex)
    },

    changeDateFormat(date) {
        const d = new Date(date)
        let result = moment(d).format('YY-MM-DD')
        if(result == "Invalid date") return ''
        return result
    },

    onPost(id) {
      this.$router.push('/comm/recruit/'+id)
    }

  },

  computed : {
    ...mapState([
      'RECRUIT_LIST', 'RECRUIT_LIST_TOTAL'
    ]),

    totalIndex() {
      const count = this.RECRUIT_LIST_TOTAL;
      return parseInt(count/10) + (parseInt(count%10)>0? 1:0);
    }
  }
}
</script>

<style>

</style>